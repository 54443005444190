<template>
  <el-dialog :visible.sync="visible" title="回执上传" append-to-body :close-on-click-modal="false" @closed="form = null"  width="640px">
    <template v-if="form">
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <el-form-item label="签收回执" prop="_pics">
            <file-uploader v-model="form._pics" :entity-id="form.fileId" folder="outbount/receive" multiple required />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="text" @click="visible = false">取消</el-button>
          <el-button :loading="saving" type="primary" @click="doSave">确定</el-button>
        </div>    
    </template>
  </el-dialog>
</template>

<script>
import {uploadReturn} from "@/api/storageManage";
export default {
  data() {
    return {
      visible: false,
      form: null,  
      saving:false,
      rules: {
        _pics: [{ required: true, message: "请上传回执" }],    
      },    
    };
  },

  methods: {
    
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
            this.saving = true;
            let form = JSON.parse(JSON.stringify(this.form));                        
              uploadReturn(form).then(res => {
                this.$message({
                  type: "success",
                  message: "保存成功!"
                });
                this.visible = false;
                this.$parent.init();
              })
              .finally(_ => {
                this.saving = false;
              });
              
        });
    },

    resetForm(id,fileId) {
      this.form ={id:id,fileId:fileId,_pics:null};
      if(!this.form.fileId) this.form.fileId = this.$uuid();
      // if (data) {
      //   this.loading = true;
      //   getById(data.id)
      //     .then((res) => {
      //       res.dateRange = [res.begTime, res.endTime];
      //       this.form = res;
      //     })
      //     .finally(() => {
      //       this.loading = false;
      //     });
      // } else {
      //   this.form = {
      //     name: "",
      //     disEntId: null,
      //     dateRange: null,
      //     status: 0,
      //     sublist: [],
      //   };
      // }
      this.visible = true;
    },
  },
};
</script>