<template>
  <el-dialog :visible.sync="dialog" :title="`选择${orderType ? orderTypeName[orderType] : ''}`" append-to-body :close-on-click-modal="false" @closed="cancel" width="800px">
    <div class="head-container">
      <el-input v-model="query.formCode" class="filter-item" :maxlength="20" :placeholder="`输入${orderTypeName[orderType]}号搜索`" @keypress.enter.native="toQuery" style="width: 180px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
    </div>

    <el-table ref="list" v-loading="loading" row-key="id" :data="data" highlight-current-row @current-change="handleOrderChange">
      <template v-if="orderType === 'consignment'">
        <el-table-column prop="formCode" label="发货通知单号" min-width="150" />
        <el-table-column prop="receiverName" label="收货人" min-width="150" />
        <el-table-column prop="warehouseName" label="发货仓库" min-width="150" />
        <el-table-column label="预计发货日期" min-label="150" :formatter="r => { return new Date(r.planeTime).format('yyyy/MM/dd'); }" />
      </template>
      <template v-else-if="orderType === 'return'">
        <el-table-column prop="formCode" label="退货单号" min-width="150" />
        <el-table-column prop="sellerName" label="供应商" min-width="150" />
        <el-table-column label="退货日期" min-width="150" :formatter="r => { return new Date(r.backDatetime).format('yyyy/MM/dd'); }" />
      </template>
      <template v-else-if="orderType === 'allocation'">
        <el-table-column prop="formCode" label="调拨单号" min-width="150" />
        <el-table-column prop="outWarehouseName" label="发货库房" min-width="150" />
        <el-table-column prop="inWarehouseName" label="收货库房" min-width="150" />
      </template>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next" @size-change="sizeChange" @current-change="pageChange" v-if="total > size" />

    <div slot="footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button type="primary" :disabled="!current" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import initData from "@/mixins/initData";
import request from "@/utils/request";

export default {
  mixins: [initData],
  data() {
    return {
      dialog: false,
      current: null,
      orderType: null,
      orderTypeName: {
        consignment: "发货通知单",
        return: "退货单",
        allocation: "调拨单"
      },
      orderTypeVal: {
        consignment: 2,
        return: 4,
        allocation: 6
      },
      query:{
        formCode:null,
      }
    };
  },
  methods: {
    cancel() {
      this.$refs.list && this.$refs.list.setCurrentRow();
    },
    beforeInit() {
      let enable = true;
      this.params = { formCode: this.query.formCode };
      switch (this.orderType) {
        case "consignment":
          this.url = "api/invoice";
          this.params.status = 1;
          break;
        case "return":
          this.url = "api/purchaseBackForm";
          this.params.status = 2;
          break;
        case "allocation":
          this.url = "api/allocationForm";
          this.params.status = 1;
          break;
        default:
          enable = false;
          break;
      }
      return enable;
    },
    show(type) {
      this.current = null;
      this.orderType = type;
      this.toQuery();
      this.dialog = true;
    },
    handleOrderChange(data) {
      this.current = data;
    },
    handleSubmit() {
      if (this.current) {
        this.$emit("select", this.orderTypeVal[this.orderType], this.current);
        this.dialog = false;
      }
    }
  }
};
</script>