import request from '@/utils/request'


export function get(id) {
  return request({
    url: 'api/inoutForm/' + id,
    method: 'get'
  })
}

export function add(data) {
  return request({
    url: 'api/inoutForm',
    method: 'post',
    data
  })
}

export function addByPurchaseConsignmentOrder(data) {
  return request({
    url: 'api/orderSendForm/in',
    method: 'post',
    data
  })
}


export function del(id) {
  return request({
    url: 'api/inoutForm/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/inoutForm',
    method: 'put',
    data
  })
}
export function cancel(data) {
  return request({
    url: 'api/inoutForm/cancel',
    method: 'put',
    data
  })
}
export function finish(id) {
  return request({
    url: 'api/inoutForm/finish',
    method: 'put',
    data: {
      id: id
    }
  })
}

export function saveStorage(data) {
  return request({
    url: 'api/inoutForm/finish',
    method: 'put',
    data
  })
}

export function getByConsignmentOrder(orderId) {
  return request({
    url: "api/inoutForm/Invoice/" + orderId,
    method: "get"
  })
}


export function uploadReturn(data) {
  return request({
    url: 'api/inoutForm/uploadReturn',
    method: 'put',
    data
  })
}