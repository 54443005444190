<template>
  <div class="v" :class="targetDependFormCode ? '' : 'app-container'">
    <div class="head-container" v-if="!targetDependFormCode">
      <el-date-picker size="small" class="filter-item" v-model="query.dateRange" type="daterange" value-format="timestamp" range-separator="至" start-placeholder="出库日期" end-placeholder :default-time="defaultTime" @change="toQuery" style="width: 280px;" />
      <quick-select class="filter-item" v-model="query.storageId" clearable displayField="name" url="api/warehouse" placeholder="出库库房" filterable @change="toQuery" style="width:180px" />
      <!-- <el-select v-model="query.formType" placeholder="出库类型" style="width:100px" class="filter-item" clearable @change="toQuery" >
        <el-option v-for="(v,k) in storageTypes" :key="k" :label="v" :value="k"></el-option>
      </el-select>-->
      <el-select v-model="query.status" filterable clearable placeholder="单据状态" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="编辑中" :value="0" />
        <el-option label="已出库" :value="1" />
      </el-select>
      <el-select v-model="query.haveReturnDoc" filterable clearable placeholder="签收回执" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="已上传" :value="true" />
        <el-option label="未上传" :value="false" />
      </el-select>
      <el-input v-model.trim="query.formCode" clearable placeholder="输入出库单号进行搜索" class="filter-item" style="width: 180px" @keyup.enter.native="toQuery" />
      <el-input v-model.trim="query.code" clearable placeholder="关联单号/合同号搜索" class="filter-item" style="width: 180px" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" border size="small" height="200px">
      <el-table-column prop="warehouseName" label="库房" width="140" show-overflow-tooltip />
      <el-table-column prop="formCode" label="单号" width="120" />
      <el-table-column prop="dependFormCode" label="关联单号" width="120" v-if="!targetDependFormCode" />
      <el-table-column prop="contractNo" label="合同号" width="140" />
      <el-table-column prop="shopName" label="门店" width="220" show-overflow-tooltip />
      <el-table-column prop="formTime" label="出库日期" width="100" :formatter="r => {return new Date(r.formTime).format('yyyy/MM/dd');}" />
      <el-table-column prop="info" label="备注" min-width="150" show-overflow-tooltip />
      <el-table-column label="状态" width="110" align="center">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="签收回执" width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.haveReturnDoc">已上传</el-tag>
          <el-tag type="info" v-else>未上传</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="50px" v-if="targetDependFormCode">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">查看</el-button>
        </div>
      </el-table-column>
      <el-table-column width="220px" fixed="right" v-else>
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">{{scope.row.status === 0 ? "编辑" : "查看"}}</el-button>
          <el-dropdown @command="print(scope.row.id, $event)">
            <span class="fs-mini" style="line-height: 25px;">
              打印
              <i class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="retail-outbound">出库模板</el-dropdown-item>
              <el-dropdown-item command="retail-outbound-consignment">发货模板</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button size="mini" type="text" @click="upload(scope.row)" v-if="scope.row.status === 1">回执上传</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180" v-if="scope.row.status === 0">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
          <el-button size="mini" type="text" @click="cancelLibrary(scope.row)" v-if="scope.row.status === 1 && checkPermission(['RETAIL_OUT','RETAIL_OUT_CANCEL'])">反审核</el-button>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <eForm ref="form" :target-depent-form-code="targetDependFormCode" />
    <reference-order ref="referenceOrder" @select="addByOrder" />
    <uploadForm ref="uploadForm" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { cancel, del } from "@/api/storageManage";
import eForm from "./form";
import uploadForm from "./uploadReturn";
import referenceOrder from "./reference-order";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { eForm, referenceOrder, uploadForm },
  mixins: [initData],
  props: {
    targetDependFormCode: String,
  },
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      uploadReturnDoc: false,
      defaultTime: ["00:00:00", "23:59:59"],
      storageTypes: {
        2: "销售出库",
        4: "退货出库",
        6: "调拨出库",
        8: "门店销售出库",
        0: "其他",
      },
      status: [
        { label: "编辑中", type: "info" },
        { label: "已出库", type: "success" },
      ],
      query: {
        storageId: null, //库房id
        dateRange: null,
        code: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/inoutForm";
      const sort = "formCode,desc";

      let query = JSON.parse(JSON.stringify(this.query));
      // 时间范围
      if (query.dateRange && query.dateRange.length === 2) {
        query["beginTime"] = query.dateRange[0];
        query["endTime"] = query.dateRange[1];
      }
      if (this.targetDependFormCode) {
        query.dependFormCode = this.targetDependFormCode;
      }
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
          inoutType: 1,
          formType: 8,
          sort: sort,
        },
        query
      );
      // 库房
      // if (query.storageId) {
      //   this.params["warehouseId"] = query.storageId;
      // }
      // // 单号
      // if (query.code) {
      //   this.params["formCode"] = query.code;
      // }
      // if (query.status) {
      //   this.params["status"] = query.status;
      // }
      // if (query.haveReturnDoc) {
      //   this.params["haveReturnDoc"] = query.haveReturnDoc;
      // }
      // this.params["formType"] = 8;
      return true;
    },
    print(id, type) {
      this.$print(type, id);
    },
    upload(row) {
      this.$refs.uploadForm &&
        this.$refs.uploadForm.resetForm(row.id, row.fileId);
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    addByOrders(orderType) {
      this.$refs.referenceOrder && this.$refs.referenceOrder.show(orderType);
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm();
    },
    addByOrder(type, order) {
      this.$refs.form && this.$refs.form.resetForm(null, type, order);
    },
    edit(data) {
      this.$refs.form && this.$refs.form.resetForm(data);
    },
    cancelLibrary(row) {
      let data = {
        id: row.id,
        info: row.info,
      };
      this.$confirm("此操作将执行取消出库操作, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          cancel(data)
            .then((res) => {
              this.init();
              this.$notify({
                title: "取消出库成功",
                type: "success",
                duration: 2500,
              });
            })
            .catch((err) => {
              console.log(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      // let params = {formTimeMix:this.params.beginTime,formTimeMax:this.params.endTime};
      download("api/inoutForm/items/buyer/download", this.params)
        .then((result) => {
          downloadFile(result, "零售出库明细数据", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>